@import "../../variables";

.header {
  background: $background-nav;
  box-shadow: 0 0 4rem rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
  a {
    color: $color-third;
    font-family: OpenSansBold;
    text-transform: uppercase;
    transition: ease-in-out 0.1s;
    &:hover,
    &:focus {
      color: $color-secondary;
      text-decoration: none;
    }
  }
  ul {
    @media screen and (min-width: 768px) {
      display: flex;
      li:not(:last-child) {
        margin-right: $spacing;
      }
    }
  }
  .wrapper {
    padding: $spacing #{$spacing * 2};
    @media screen and (min-width: 1200px) {
      margin: 0 auto;
      width: 70vw;
    }
  }
  @media screen and (max-width: 1199px) {
    font-size: 1.5rem;
  }
  @media screen and (min-width: 1200px) {
    max-height: 54px;
  }
}
