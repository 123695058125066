@import "../../variables";

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.footer {
  margin: #{$spacing * 2} 0;
  opacity: 0.5;
  text-align: center;
  .icon {
    font-size: 2rem;
    &:not(:last-child) {
      margin-right: $spacing;
    }
  }
  .react-icon {
    animation: spin linear 8s infinite;
    color: #61dafb;
  }
  .sass-icon {
    color: #cf649a;
  }
}
