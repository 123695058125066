@import "../../variables";

.portfolio {
  h1 span {
    color: $color-secondary;
    font-weight: bold;
  }
  .categories {
    margin-bottom: $spacing;
    display: flex;
    /* gap: 1rem; */
    align-items: center;
    flex-wrap: wrap;
    > * {
      /* thanks safari */
      margin: 0.5rem 0.5rem 0 0;
      &:first-child {
        margin-top: 0;
      }
    }
    p {
      @media screen and (max-width: 1199px) {
        flex-basis: 100%;
      }
    }
    .category {
      background: $color-third;
      border-radius: $border-radius;
      border: 0;
      cursor: pointer;
      font-family: inherit;
      font-size: 1rem;
      font-weight: bold;
      padding: 0.5em 1em;
      text-transform: uppercase;
      transition: ease 0.25s;
      &:hover,
      &:focus,
      &.category-active {
        color: $color-third !important;
        background: $color-secondary !important;
      }
    }
  }
  .projects {
    display: grid;
    gap: $spacing;
    .project-link {
      position: relative;
      transition: ease 0.25s;
      border-radius: $border-radius;
      figure {
        position: relative;
        img {
          width: 100%;
          position: relative;
          min-height: 200px;
        }
        figcaption {
          background: $color-secondary;
          bottom: 0;
          color: $color-third;
          padding: $spacing;
          position: absolute;
          text-align: center;
          transition: height ease 0.2s;
          width: 100%;
          .title {
            font-size: 1.3rem;
            font-weight: bold;
            // start text stays on 1 line
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            // end
            @media screen and (min-width: 1200px) {
              font-size: 1.5rem;
            }
          }
        }
      }
      &:hover,
      &:focus {
        box-shadow: 0 0 3rem $shadow;
        transform: scale(1.02) translateY(-0.35rem);
        z-index: 1;
        opacity: 1;
      }
      @media screen and (min-width: 1200px) {
        opacity: 0.8;
      }
    }
    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
