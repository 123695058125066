@import "variables";

/* ==========
  ANIMATIONS
=========== */

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

/* ==========
  FONTS
=========== */

@font-face {
  font-family: OpenSansReg;
  src: url("./assets/OpenSans-Regular.ttf");
}
@font-face {
  font-family: OpenSansBold;
  src: url("./assets/OpenSans-Bold.ttf");
}
@font-face {
  font-family: Bitter;
  src: url("./assets/Bitter.ttf");
}

/* ==========
  RESET
=========== */

*,
::before,
::after {
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  font-weight: normal;
  margin: 0;
  padding: 0;
}
:focus {
  outline: none;
}

/* ==========
  GLOBAL STYLES
=========== */

body {
  background: $color-bg;
  color: $color-third;
  font-family: Bitter, sans-serif;
}
main {
  border-radius: $border-radius;
  padding: 0 env(safe-area-inset-right) 0 env(safe-area-inset-left);
}
ul {
  list-style-type: none;
}
a {
  color: inherit;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
    color: $color-secondary;
  }
}
a:not([href]),
button,
input[type="button"],
input[type="submit"] {
  cursor: pointer;
}
.green {
  color: $color-secondary !important;
}

/* ==========
  REST
=========== */

.loading {
  text-align: center;
  padding: 2rem 0;
}
.loading-icon {
  font-size: 2em;
  margin: 0 auto;
  animation: loading 1s linear infinite;
  color: $color-secondary;
}
.loading-icon-white {
  font-size: 2em;
  margin: 0 auto;
  animation: loading 1s linear infinite;
  color: $color-third;
}
main > h1 {
  color: $color-third;
  font-family: Bitter, sans-serif;
  margin-bottom: $spacing;
  text-align: center;
}
.container {
  padding: $spacing;
}

@media screen and (max-width: 1199px) {
  main > h1 {
    font-size: 1.7rem;
  }
}
@media screen and (min-width: 1200px) {
  main > h1 {
    text-align: left;
  }
  .container {
    padding: 2rem;
    width: 70vw;
    margin: 0 auto;
  }
}
