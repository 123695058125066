@import "../../variables";

.image-slider {
  background: $color-primary;
  border-radius: $border-radius;
  padding: 1rem;
  position: relative;
  width: 100%;
  .current-slide {
    background: $color-secondary;
    border-top-right-radius: $border-radius;
    width: 6ch;
    padding: 0 0.5rem;
    position: absolute;
    right: $spacing;
    text-align: center;
    top: $spacing;
  }
  img {
    border-radius: $border-radius;
    width: 100%;
    vertical-align: middle; // removes phantom margin below image
  }
  button {
    background: $color-secondary;
    color: $color-third;
    cursor: pointer;
    display: flex;
    font-size: 1.5rem;
    opacity: 0.8;
    padding: $spacing;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: ease 0.2s;
    &:hover,
    &:focus {
      opacity: 1;
    }
    &.btn-prev {
      border-bottom-right-radius: $border-radius;
      border-top-right-radius: $border-radius;
      left: 0;
    }
    &.btn-next {
      border-bottom-left-radius: $border-radius;
      border-top-left-radius: $border-radius;
      right: 0;
    }
  }
}
