@import "../../variables";

.project-page {
  display: grid;
  line-height: 1.8rem;
  > div {
    padding: $spacing 0;
    @media screen and (min-width: 1200px) {
      padding: $spacing #{$spacing * 2};
    }
  }
  span {
    color: grey;
  }
  .controls {
    a {
      display: inline-flex;
      align-items: center;
      background: $color-secondary;
      padding: 0.5rem 1rem;
      border-radius: $border-radius;
      color: $color-third;
    }
    @media screen and (max-width: 1199px) {
      order: 0;
    }
    @media screen and (min-width: 1200px) {
      grid-column: 1/3;
    }
  }
  .left {
    .category {
      margin-bottom: $spacing;
      &::before,
      &::after {
        content: "/";
        color: $color-secondary;
        font-weight: bold;
        margin: 0 #{$spacing / 2};
      }
    }
    .image-slider {
      width: 100%;
      .image-slider-wrapper {
        width: 100%;
        overflow: hidden;
      }
    }
    @media screen and (max-width: 1199px) {
      order: 2;
    }
  }
  .right {
    .title {
      margin-bottom: $spacing;
    }
    .description {
      margin-top: $spacing;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      // gap: 0.5rem;
      .tag {
        color: $color-third;
        background: $color-secondary;
        padding: 0.2rem 0.5rem;
        display: inline;
        border-radius: $border-radius;
        // thanks safari
        margin: 0.25rem 0 0.25rem 0.5rem;
      }
    }
    .content {
      list-style-type: circle;
      margin: $spacing;
    }
    .url {
      margin-top: #{$spacing * 2};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      @media screen and (max-width: 600px) {
        max-width: 30ch;
      }
      a {
        text-decoration: underline;
      }
    }
    @media screen and (max-width: 1199px) {
      order: 1;
    }
    @media screen and (min-width: 1200px) {
      padding-top: 3rem;
    }
  }
  @media screen and (min-width: 1200px) {
    font-size: 1.2rem;
    grid-template-columns: 1fr 1fr;
  }
}
