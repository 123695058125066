@import "../../variables";

.home {
  text-align: center;
  position: relative;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  .about {
    background: $color-primary;
    padding: #{$spacing * 2};
    margin-top: $spacing;
    .content {
      color: #eee;
      font-family: Consolas, monospace;
      font-weight: bold !important;
      text-align: left;
      display: inline-block;
      p {
        margin-top: #{$spacing / 2};
      }
      span {
        color: $color-secondary;
      }
      @media screen and (min-width: 1200px) {
        font-size: 2rem;
      }
    }
  }
}
