@import "../../variables";
.contact {
  font-size: 1.2rem;
  .wrapper {
    display: grid;
    > section {
      padding: $spacing;
    }
    @media screen and (min-width: 1200px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  #helperText {
    opacity: 0.6;
  }
  .left {
    background: $color-primary;
    display: flex;
    flex-direction: column;
    /* gap: $spacing; */
    > div:not(:first-child) {
      /* Used instead of gap, thanks safari! */
      margin-top: $spacing;
    }
    h4 {
      color: $color-secondary;
      font-weight: bold;
    }
    .social-media a {
      font-size: 3rem;
      transition: ease 0.1s;
      &:hover,
      &:focus {
        color: $color-secondary;
      }
    }
  }
  .right {
    background: $color-secondary;
    input,
    textarea {
      border-radius: $border-radius;
      display: block;
      font-size: inherit;
      font-family: inherit;
      padding: #{$spacing / 2} $spacing;
      width: 100%;
      &:focus {
        background: $color-third;
        &:invalid {
          box-shadow: 0 0 0 3px #c73e00;
        }
      }
    }
    textarea {
      resize: none;
    }
    input[type="submit"] {
      opacity: 0.8;
      background: $color-third;
      transition: ease 0.25s;
      /* safari fix */
      appearance: none;
      &:hover,
      &:focus {
        opacity: 1;
      }
    }
    h2 {
      margin-bottom: $spacing;
    }
    fieldset {
      margin-bottom: $spacing;
    }
  }
}
